import 'picturefill';
import LazyLoad from 'vanilla-lazyload';

function initLazyLoad() {

	const lazyload = new LazyLoad({
		elements_selector: 'img[data-original]',
		show_while_loading: true,
		callback_set: function (img) {
			picturefill({
				reevaluate: true,
				elements: [img]
			});
		},
	});
};

if (document.body.classList.contains("page-template-tmpl-upcoming-webinars")) {

	window.addEventListener('DOMContentLoaded', initLazyLoad);

	jQuery(function ($) {

		// console.log("Current Webinars Page: " + past_webinars_load_more_params.current_page);

		$('.webinar-load-more').click(function () {

			var button = $(this),
				data = {
					action: 'loadmore',
					query: past_webinars_load_more_params.posts,
					page: past_webinars_load_more_params.current_page,
				};

			$.ajax({
				url: past_webinars_load_more_params.ajaxurl,
				data: data,
				type: 'POST',
				beforeSend: function (xhr) {
					button.text('Loading...');
				},
				success: function (data) {
					if (data) {
						// If there's data
						button.text('Load More') // Change button text

						$('#webinars-past-listing').append(data); // Append data to parent container

						initLazyLoad();

						past_webinars_load_more_params.current_page++;

						// console.log(data);
						// console.log('Current Webinars Page: ' + past_webinars_load_more_params.current_page);
						// console.log('Max Webinars Page:' + past_webinars_load_more_params.max_page);

						if (past_webinars_load_more_params.current_page == past_webinars_load_more_params.max_page)
							// if last page, remove the button
							button.remove();
					} else {
						// if no data, remove the button
						button.remove();
					}
				}
			});
		});

	});

}
