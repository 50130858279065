// Fonts
import './modules/theme/font-loader';
// Polyfills that should run automatically
import './modules/theme/object-fit-images';
import './modules/theme/svgxuse';
// Modules
import './modules/theme/box-carousel';
import './modules/theme/checkbox-select';
import './modules/theme/content-box-slider';
import './modules/theme/forms';
import './modules/theme/evidence-forum';
import './modules/theme/impact-statement-slider';
import './modules/theme/lightweight-youtube-embed';
import './modules/theme/links';
import './modules/theme/menu-no-click';
import './modules/theme/mobile-menu';
import "./modules/theme/media-content";
import "./modules/theme/panel-slider";
import './modules/theme/resource-center';
import './modules/theme/site-header';
import './modules/theme/site-search';
import './modules/theme/story-slider';
import './modules/theme/testimonial-slider';
import './modules/theme/publication-slider';
import './modules/theme/vanilla-lazyload';
import "./modules/theme/webinars";
import "./modules/theme/top-anchor";
