/* eslint-disable prefer-arrow-callback */
/**
 * Sticky header functionality
 *
 * @package ppd
 * @since   1.0.0
 */

import $ from 'jquery';
import { els } from './variables';

/**
 * Tracks the current scroll position on the page.
 *
 * @type {Number}
 */
let currentScrollPoint;

/**
 * The point (in pixels) at which the header class should be toggled.
 *
 * @type {Number}
 */
const mastheadScrollPoint = 540;

/**
 * Toggle the top anchor visible class.
 */
function maybeToggleVisibleClass() {
	/**
	 * Update the current scroll position.
	 *
	 * @type {Number}
	 */
	currentScrollPoint = (els.html && els.html.scrollTop) || els.body.scrollTop;

	/**
	 * Check scroll status and toggle .scrolled accordingly.
	 */
	if (currentScrollPoint >= mastheadScrollPoint) {
		els.anchor.classList.add('visible');
	} else {
		els.anchor.classList.remove('visible');
	}
}

$('.top-anchor').click(function () {
	console.log('clicked');
	$('html, body').animate({
		scrollTop: 0,
	}, 600);
	return false;
});

els.body.addEventListener('scroll', maybeToggleVisibleClass);
window.addEventListener('scroll', maybeToggleVisibleClass);
window.addEventListener('load', maybeToggleVisibleClass);
