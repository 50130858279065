/**
 * Import dependencies.
 */
import $ from 'jquery';

/**
 * Check if element is fully visible within the viewport.
 * @param  {Object}  el HTMLElement or jQuery Object.
 * @return {Boolean}
 */
export function isElementInViewport(el) {

    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&/*or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)/*or $(window).width() */);
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {Function} 	func      	Function to debounce
 * @param  {Number} 	wait      	Time to wait in milliseconds
 * @param  {Boolean} 	immediate 	If true is passed, trigger the function on the leading edge, instead of the trailing.
 * @return {Function}
 */
export function debounce(func, wait = 100, immediate = true) {
    var timeout;
    return function() {
        var context = this,
            args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate)
                func.apply(context, args);
            };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(context, args);
        };
};

/**
 * Loop through an array/nodelist and call the passed callback function.
 * @param  {Array}   	array    	Array to be looped over.
 * @param  {Function} 	callback 	Callback function.
 * @param  {???}   		scope
 */
export function forEach(array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]);
    }
}

/**
 * Append a hash to the current URL.
 * @param {String} hash
 */
export function addHashToURL(hash) {
    if (history.pushState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + hash;
        window.history.pushState({
            path: newurl
        }, '', newurl);
    }
}

/**
 * Scroll the document to the provided element.
 * @param  {Object} 	el     	jQuery object
 * @param  {Integer} 	offset 	Offset from the top of the viewport
 */
export function scrollToEl(el, offset) {
    $('html, body').animate({
        scrollTop: el.offset().top - offset
    }, 500);
}

/**
 * Helper method for determining if an input has a value. 
 * - Return true if has supplied value
 * - Return false if no value
 * @param  {NodeList} 	el
 */
export function hasValue(el) {
	
	if ( ! el || el.value === undefined ) {
		return false;
	}
	
	// Validate if has value or not
	if ( el.value === "" ) {
		return false;
	} else {
		return true;
	}
	
};

/**
 * Merge two objects together.
 * @param  {Object} 	obj1
 * @param  {Object} 	obj2
 */
export function mergeObjects(obj1, obj2) {
    
    const obj3 = {};
    let attr;
    
    for(attr in obj1) {
        obj3[attr] = obj1[attr];
    }
    
    for(attr in obj2) {
        obj3[attr] = obj2[attr];
    }
    
    return obj3;
    
}

/**
 * Check if object is empty.
 * @link https://stackoverflow.com/a/3427021
 * @param  {Object}  obj
 */
export function isEmpty(obj) {
	for( let key in obj) {
		return !obj.hasOwnProperty(key);
	}
	return true;
}

/**
 * Cross browser matches method
 * @link https://codepen.io/atwulf/pen/gMbvrV
 * @param  {NodeList}  el
 * @param  {string}    selector
 */
export function matchesSelector(el, selector) {
	
	let method =
		Element.prototype.matches ||
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector ||
		Element.prototype.mozMatchesSelector ||
		Element.prototype.oMatchesSelector ||
		Element.prototype.matchesSelector;
	
	return method.call(el, selector);
	
}

/**
 * Check the userAgent for common touch devices.
 *
 * @since 1.0.1
 */
export function isTouchDevice() {
	return navigator.userAgent.match(/Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i) !== null;
}