import $ from 'jquery';
import 'picturefill';
import * as helpers from '../global/helpers';
import {els} from './variables';

/**
 * Initial jquery uniform for file inputs.
 * @param {Event} e
 */
function initUniform(e) {
	/**
	 * Run uniform on upload elements only.
	 */
	if ($('[type="file"]').length) {
		
		$('[type="file"]').each(function(){
			let $this = $(this);
			/**
			 * If .uploader is not the parent, add it.
			 */
			if ( !$this.parent().hasClass('uploader') ) {
 				let classes = $(this).attr('class');
				$this.uniform();
				$this.removeClass(classes).parents('.uploader').addClass(classes);
			}

			/**
			 * Fixes issue on Windows Chrome where 'ginput_container_fileupload' class is not being appended
			 */
			$this.closest('.ginput_container').addClass('ginput_container_fileupload');
		});
	}
}

/**
 * Places labels inside inputs. On focus, slides label above input.
 * Gravity forms with class 'fancy-labels' will receive this treatment. 
 */
function initGravityFormsFancyLabels(e) {
	const gfields = document.querySelectorAll('.fancy-labels .gfield');
	helpers.forEach(gfields, (i, gfield) => {
		const label = gfield.querySelector('.gfield_label');
		const input = gfield.querySelector('input:not([type="checkbox"]):not([type="radio"]), select, textarea');

		if(label && input && input.nodeName !== 'SELECT') {
			input.addEventListener('focus', (e) => {
				gfield.classList.add('active');
			});

			input.addEventListener('blur', (e) => {
				if(!input.value) {
					gfield.classList.remove('active');
				}
			});
		} else {
			gfield.classList.add('active');
		}
		
		if ( input.value !== '' ) {
			gfield.classList.add('active');
		}
		
	});
}

function maybeScrollToGravityFormsErrors() {
	
	const formsWithErrors = document.querySelectorAll('.gform_validation_error');
	if(formsWithErrors.length > 0) {
	
		const images = document.querySelectorAll('img[data-original]');
		const sources = document.querySelectorAll('picture source');
		
		if ( images.length > 0 ) {
			
			helpers.forEach( images, (i, image) => {
				image.src = image.dataset.original;
				image.removeAttribute('data-original');
				if ( image.dataset.originalSet ) {
					image.srcset = image.dataset.originalSet;	
					image.removeAttribute('data-original-set');
				}
			});
			
			if ( sources.length > 0 ) {
				helpers.forEach( sources, (i, source) => {
					source.srcset = source.dataset.originalSet;
					source.removeAttribute('data-original-set');
				});
			}
			
			picturefill({
				reevaluate: true
	        });

	        window.addEventListener('load', (e) => {			
				helpers.scrollToEl($(formsWithErrors[0]), els.header.offsetHeight);
			})
			
		} else {
	        window.addEventListener('load', (e) => {			
				helpers.scrollToEl($(formsWithErrors[0]), els.header.offsetHeight);
			})
		}
	}
}

document.addEventListener('DOMContentLoaded', (e) => {
	initGravityFormsFancyLabels();
	maybeScrollToGravityFormsErrors();
	initUniform();
});
//! Re-run Uniform functions on gravity forms reloads (without page refresh).
$(document).bind('gform_post_render', function(){
	initUniform();
});