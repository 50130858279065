/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import {els, breakpoints} from './variables';

/**
 * Header scroll class
 * @type {String}
 */
const headerScrollClass = 'site-header--scrolled';

/**
 * Cross-browser requestAnimationFrame function.
 *
 * @since 1.0.0
 */
window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(f){setTimeout(f, 1000/60)};

/**
 * On scroll from top of window, add box-shadow.
 * @param {Event} e
 */
function handleHeaderScroll( e ) {
	
	const scrollY = window.pageYOffset || document.documentElement.scrollTop;
	
	if ( scrollY > 0 ) {
		addHeaderScrollState();
	} else {
		removeHeaderScrollState();
	}
	
}

/**
 * Add scroll class
 */
function addHeaderScrollState() {
	els.header.classList.add( headerScrollClass );
}

/**
 * Remove scroll class
 */
function removeHeaderScrollState() {
	els.header.classList.remove( headerScrollClass );
}

window.addEventListener( 'scroll', () => requestAnimationFrame(handleHeaderScroll) );