/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/**
 * Layer Type: blockSpotlightSliders content with callouts
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import 'slick-carousel';
import * as helpers from '../global/helpers';

/**
 * Media Content layer
 *
 * @type {NodeList}
 */
const blockPanelMediaContent = document.querySelectorAll('.layer--media-content');

/**
 * Do synchronized slick sliders.
 */
function initTargetPrevLayer() {

	$(blockPanelMediaContent).prev('.layer').addClass('layer--media-content__no-padding');
     
}


window.addEventListener('DOMContentLoaded', initTargetPrevLayer);