/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import {els} from './variables';

/**
 * Scroll to anchor and update URL
 */
$(document).on('click', 'a[href*="#"]:not([href="#"])', function() {
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
		var hash = this.hash;
		var target = $(hash);
		target = target.length ? target : $('[name=' + hash.slice(1) + ']');
		if (target.length) {
			scrollToHash(target, hash);
		}
	}
});

function scrollToHash(target, hash) {
	helpers.scrollToEl(target, els.header.offsetHeight);
	helpers.addHashToURL(hash);
	return false;
}

function maybeScrollToHashOnLoad() {
	// Remove the # from the hash, as different browsers may or may not include it
    var hash = location.hash.replace('#','');

	if ( hash != '' ) {
		scrollToHash( $('#' + hash), '#' + hash );
	}
}

/**
 * Add rel tag to target="_blank" links just in case it isn't already there.
 */
$('a[target="_blank"]').attr('rel', 'noopener noreferrer');


window.addEventListener('DOMContentLoaded', maybeScrollToHashOnLoad);