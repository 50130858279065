/**
 * Import dependencies.
 */
import $ from 'jquery';
	
// Defines the class used to indicate active state on checkbox-select field. 
const activeClass = 'checkbox-select--open';


// Fires when the checkbox select group is clicked.
$(document).on('click', '.checkbox-select__group-label', function(e){
	
	const $this = $(this);
	const $activeParent = $this.closest('.checkbox-select');
	
	$('.checkbox-select').not($activeParent).removeClass(activeClass);
	$($activeParent).toggleClass(activeClass);
	
});


// Fires when the document is clicked
$(document).on('click', function( event ) {

	// If the clicked element is not the checkbox select label, or a child of checkbox select, remove all active classes to collapse. 
	if ( event.target.className !== 'checkbox-select__group-label' && ! $(event.target).closest('.checkbox-select').length ) {
		$('.checkbox-select').removeClass(activeClass);	
		$('.checkbox-select__group').each(function(){
			$(this).scrollTop( 0 );
		});
	}
	
});