/**
 * Import dependencies.
 */
import $ from 'jquery';
import 'slick-carousel'; // hardcoded slick plugin. odd issue with gulp using node modules version.

/**
 * Slider elements
 * @type {NodeList}
 */
const publicationSlider = document.querySelectorAll('.card-box-slider');

/**
 * Class used for each slide.
 * @type {String}
 */
const pubSlideClass = 'card-slide';

function initSlickPublications() {
	
	$(publicationSlider).each(function() {
		let $this = $(this);
		if ($this.find('.' + pubSlideClass).length > 1) {
			$this.slick({
				infinite: true,
				slidesToShow: 3,
				slidesToScroll: 3,
				nextArrow:'<button class="slider-arrow-custom slider-arrow-custom--next slider-arrow--md"></button>',
				prevArrow:'<button class="slider-arrow-custom slider-arrow-custom--prev slider-arrow--md"></button>',
				responsive: [
				    {
				      breakpoint: 1024,
				      settings: {
				        slidesToShow: 3,
				        slidesToScroll: 3,
				        dots: false
				      }
				    },
				    {
				      breakpoint: 991,
				      settings: {
				        slidesToShow: 2,
				        slidesToScroll: 2
				      }
				    },
				    {
				      breakpoint: 769,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1
				      }
				    }	
				]
			});
		}
	});
};

window.addEventListener('DOMContentLoaded', initSlickPublications);