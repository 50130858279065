/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import {els, breakpoints} from './variables';

/**
 * Desktop search input for focusing on click
 * @type {Node}
 */
const searchInputDesktop = document.getElementById('js-search-input-desktop');

/**
 * Class added to .site-search--desktop when open.
 * @type {String}
 */
const siteSearchActiveClass = 'is-active';

/**
 * Search Form
 * @type {String}
 */
const searchForm = document.querySelectorAll( '.search-form' );

/**
 * Toggle the desktop site search container.
 */
function toggleSiteSearch() {
	if(els.siteSearchDesktop.classList.contains(siteSearchActiveClass)) {
		closeSiteSearch();
	} else {
		openSiteSearch();
	}
}

/**
 * Open the desktop site search container.
 */
function openSiteSearch() {
	els.siteSearchTrigger.classList.add(siteSearchActiveClass);
	els.siteSearchDesktop.classList.add(siteSearchActiveClass);
	window.setTimeout(function() {
		searchInputDesktop.focus();	
	}, 100);
}

/**
 * Close the desktop site search container.
 */
function closeSiteSearch() {
	els.siteSearchTrigger.classList.remove(siteSearchActiveClass);
	els.siteSearchDesktop.classList.remove(siteSearchActiveClass);
	searchInputDesktop.blur();
}

/**
 * Open/close the site search container in desktop. 
 * @param  {Event} e
 */
function handleDesktopSearchState(e) {

	/**
	 * If the menu isn't open and the event target is the navicon or any of its children, then add the menu open the menu.
	 */
	if(e.target === els.siteSearchTrigger || $(e.target).closest('#js-search-trigger').length > 0 ) {
		toggleSiteSearch();
	}
}

/**
 * Handle search submit. Prevent empty form submissions.
 * @param  {Event} e
 */
function handleSearchSubmit(e) {
	
	if ( e.target.querySelector('.search-input').value == '' ) {
		e.preventDefault();
	}
	
}

document.addEventListener('click', handleDesktopSearchState);
$(searchForm).on('submit', handleSearchSubmit);