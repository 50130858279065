import * as helpers from '../global/helpers';
import {els, breakpoints} from './variables';

/**
 * Menu items that have 'no-click' class.
 * @type {NodeList}
 */
const menuItemsNoClick = document.querySelectorAll('.menu .no-click > a');

/**
 * Add event listener to all node items with 'no-click' class.
 */
function initMenuItemNoClick() {
	/**
	 * Add event listeners for menu items with submenus.
	 */
	if(menuItemsNoClick.length > 0) {
		helpers.forEach(menuItemsNoClick, (i, item) => {
			item.addEventListener('click', function(event) {
				event.preventDefault();
			});
		});
	}
}

window.addEventListener('DOMContentLoaded', initMenuItemNoClick);