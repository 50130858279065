import 'picturefill';
import LazyLoad from 'vanilla-lazyload';

function initLazyLoad() {

	const lazyload = new LazyLoad({
		elements_selector: 'img[data-original]',
	    show_while_loading: true,
		callback_set: function(img) {
			picturefill({
				reevaluate: true,
	            elements: [img]
	        });
	    },
	});
};

window.addEventListener('DOMContentLoaded', initLazyLoad);
