/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import {els} from './variables';
import 'slick-carousel'; // hardcoded slick plugin. odd issue with gulp using node modules version.

/**
 * Slider elements
 * @type {NodeList}
 */
const storySlider = document.querySelectorAll('.story-book');

/**
 * Pager items
 * @type {NodeList}
 */
const pagerItemClass = 'story-pager__item';

/**
 * Class used for each slide.
 * @type {String}
 */
const storySlideClass = 'story';

function initSlickStoryBook() {
	
	$(storySlider).each(function() {
		let $slider = $(this);
		if ($slider.find('.' + storySlideClass).length > 1) {
			$slider.slick({
				arrows: false,
				dots: false,
				fade: true, 
				swipe: true,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							arrows: true,
							nextArrow: '<button class="slider-arrow slider-arrow--next slider-arrow--lg slider-arrow--transparent-dark"></button>',
							prevArrow: '<button class="slider-arrow slider-arrow--prev slider-arrow--lg slider-arrow--transparent-dark"></button>',
						}
			    	}
			    ]
			});
			
			$(document).on('click', '.story-book .slider-arrow', function() {
				helpers.scrollToEl($slider.closest('.layer'), els.header.offsetHeight);
			});
			
			$(document).on('click', '.' + pagerItemClass, function() {
				handlePaging(this, $slider);
			});
			
		}
	});
};

function handlePaging( el, $slider ) {	
	const pagerIndex = $(el).index(this);
	$(el).siblings().removeClass('active');
	$(el).addClass('active');
	$slider.slick('slickGoTo', pagerIndex, false );
}

window.addEventListener('DOMContentLoaded', initSlickStoryBook);