/**
 * Import dependencies.
 */
import $ from 'jquery';
import 'slick-carousel'; // hardcoded slick plugin. odd issue with gulp using node modules version.
import * as helpers from '../global/helpers';

/**
 * Slider elements
 * @type {NodeList}
 */
const boxCarousel = document.querySelectorAll('.box-carousel');

/**
 * Class used for each slide.
 * @type {String}
 */
const boxSlideClass = 'box-slide';

function initBoxCarousel() {
	
	$(boxCarousel).each(function() {
		let $this = $(this);
		if ($this.find('.' + boxSlideClass).length > 3) {
			$this.slick({
				arrows: true,
				dots: false,
				nextArrow: '<button class="slider-arrow slider-arrow--next slider-arrow--lg"></button>',
				prevArrow: '<button class="slider-arrow slider-arrow--prev slider-arrow--lg"></button>',
				slidesToScroll: 3,
				slidesToShow: 3,
				swipe: true,
				responsive: [
				{
			      breakpoint: 940,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 2,
			      }
			    },
				{
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1,
			      }
			    }
			  ]
			});
		}
	});
};

window.addEventListener('DOMContentLoaded', initBoxCarousel);