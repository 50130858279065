import {Breakpoints} from './breakpoints';

export const breakpoints = new Breakpoints(false);

export const els = {
	html: document.documentElement,
	body: document.body,
	header: document.getElementById('js-site-header'),
	mainMenuContainer: document.getElementById('js-site-navigation'),
	mainMenu: document.querySelector('.main-menu'),
	navicon: document.getElementById('js-navicon'),
	siteSearch: document.querySelector('.site-search'),
	siteSearchDesktop: document.querySelector('.site-search--desktop'),
	siteSearchTrigger: document.getElementById('js-search-trigger'),
	anchor: document.querySelector('.top-anchor'),
};
