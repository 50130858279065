(function($) {

	/* Light YouTube Embeds by @labnol */
	/* Web: http://labnol.org/?p=27941 */
	
	document.addEventListener("DOMContentLoaded",
	    function() {
	        var div, n,
	            v = document.getElementsByClassName("youtube-player");
	        for (n = 0; n < v.length; n++) {
	            div = document.createElement("div");
	            div.setAttribute("data-id", v[n].dataset.id);
	            div.innerHTML = labnolThumb(v[n].dataset.id);
				div.setAttribute("data-cc-lang-pref", v[n].dataset.ccLangPref);
				div.setAttribute("data-cc-load-policy", v[n].dataset.ccLoadPolicy);
				div.setAttribute("data-hl", v[n].dataset.hl);
	            div.onclick = labnolIframe;
	            v[n].appendChild(div);
	        }
	    }
	);
	
	function labnolThumb(id) {
		
	    var thumb = '<img data-original="' + location.protocol + '//i.ytimg.com/vi/ID/hqdefault.jpg">',
	        play = '<div class="play-btn-icon"></div>';
	    
	    return thumb.replace("ID", id) + play;
	}
	
	function labnolIframe() {
	    var iframe = document.createElement("iframe");
	    var embed = location.protocol + "//www.youtube.com/embed/ID";
	    
	    // Create required YouTube parameters
	    var parameters = {
		    autoplay: 1 ,
		    enablejsapi: 1,
		    hd: 1, 
		    rel: 0,
		    showinfo: 0,
	    };
	    
	    // Add optional YouTube parameters
		if ( this.dataset.hl !== 'undefined' && this.dataset.hl !== null ) {
		    parameters.hl = this.dataset.hl;
		}
	    if ( this.dataset.ccLangPref !== 'undefined' && this.dataset.ccLangPref !== null ) {
		    parameters.cc_lang_pref = this.dataset.ccLangPref;
		}
		if ( this.dataset.ccLoadPolicy !== 'undefined' && this.dataset.ccLoadPolicy !== null ) {
		    parameters.cc_load_policy = this.dataset.ccLoadPolicy;
		}
		
		// Add origin parameter for an security security measure/resolve protool errors
		parameters.origin = location.protocol + '//' + location.hostname;
		
	    iframe.setAttribute("src", embed.replace("ID", this.dataset.id) + '?' + encodeQueryData( parameters ));
	    iframe.setAttribute("frameborder", "0");
	    iframe.setAttribute("allowfullscreen", "1");
	    this.parentNode.replaceChild(iframe, this);
	}
	
	function encodeQueryData(data) {
		var ret = [];
		for (var d in data) {
			ret.push(encodeURIComponent(d) + '=' + encodeURI(data[d]));
		}
		return ret.join('&');
	}

})(jQuery);