/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import { els, breakpoints } from './variables';
import 'classlist-polyfill';
import 'picturefill';
import objectFitImages from 'object-fit-images';
import LazyLoad from 'vanilla-lazyload';

if (document.body.classList.contains('page-template-tmpl-resource-center')) {

	/**
	 * Public inputs
	 * @type {Array}
	 */
	const filterNames = ['material-types', 'topic', 'therapeutic-areas'];

	/**
	 * Filters form.
	 * @type {NodeList}
	 */
	const filterForm = document.getElementById('js-filters');

	/**
	 * Checkboxes.
	 * @type {NodeList}
	 */
	const checkboxes = filterForm.querySelectorAll('input[type="checkbox"]');

	/**
	 * Checkbox selectors
	 * @type {String}
	 */
	const checkboxSelector = '#js-filters input[type="checkbox"]';

	/**
	 * Selected checkboxes. Used to determine if apply is shown/hidden.
	 * @type {Array}
	 */
	let lastSubmissionValues = getSelectedCheckboxes();

	/**
	 * The last submitted filter submission. Used for loadmore.
	 * @type {Object}
	 */
	let lastSubmissionPublicFilters = getPublicFilters();

	/**
	 * The last submitted public query string.
	 * @type {String}
	 */
	let lastSubmissionQueryString = getCurrentSubmissionQueryString();

	/**
	 * Hidden inputs.
	 * @type {NodeList}
	 */
	const inputCurrentPage = document.getElementById('js-filter-current-page');
	const inputEndpoint = document.getElementById('js-filter-endpoint');
	const inputTotalPages = document.getElementById('js-filter-total-pages');
	const inputTotalPosts = document.getElementById('js-filter-total-posts');

	/**
	 * Submit button.
	 * @type {NodeList}
	 */
	const submitBtn = document.getElementById('js-btn-submit');

	/**
	 * Reset button.
	 * @type {NodeList}
	 */
	const resetBtn = document.getElementById('js-btn-reset');

	/**
	 * Load More button.
	 * @type {NodeList}
	 */
	const loadMoreBtn = document.getElementById('js-btn-load-more');

	/**
	 * Results container
	 * @type {NodeList}
	 */
	const resultsContainer = document.getElementById('js-filter-results');

	/**
	 * Button disabled class.
	 * @type {String}
	 */
	const btnDisabledClass = 'filter-btn--disabled';

	/**
	 * Button disabled class.
	 * @type {String}
	 */
	const btnHiddenClass = 'filter-btn--hidden';

	/**
	 * Default image url (fallback image when none found for a post).
	 * @type {String}
	 */
	const fallbackImage = document.getElementById('js-default-img').value;

	/**
	 * Get the public-facing form filters.
	 */
	function getPublicFilters() {

		// Empty filters object
		let currentFilters = {};

		// Iterate through our filterNames array.
		helpers.forEach(filterNames, (i, filterName) => {

			// Create an empty array of checked values for this filter group.
			let filter = [];

			// Store all the checkbox inputs for this filter name.
			let inputs = document.querySelectorAll('input[data-filter=' + filterName + ']');

			// Iterate through all the checkboxes for this filter group.
			helpers.forEach(inputs, (i, input) => {
				if (input.checked == true) {
					filter.push(input.value);
				}
			});

			// If this filter has values, add to currentFilters
			if (filter.length > 0) {
				currentFilters[filterName] = filter;
			}

		});

		return currentFilters;

	}

	/**
	 * Gets filters for REST API that should not be displayed in the query string in the
	 * address bar, such as pagination, posts_per_page parameters
	 */
	function getHiddenFilters() {

		const hiddenFilters = {};

		// Conditionally define the current page.
		if (helpers.hasValue(inputCurrentPage)) {
			hiddenFilters.page = inputCurrentPage.value;
		}

		// Return the hidden filters.
		return hiddenFilters;
	}

	/**
	 * Removes query strings in URL using replaceState()
	 */
	function resetQueryString() {
		if (history.pushState) {
			var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
			window.history.replaceState({ path: newurl }, '', newurl);
		}
	};

	/**
	 * Reset any prepopulated filters.
	 * @param  {Event} e
	 */
	function handleFiltersReset(e) {
		if (e.target === resetBtn) {

			if (!resetBtn.classList.contains(btnDisabledClass)) {

				// Reset checkboxes.
				helpers.forEach(checkboxes, (i, item) => {
					item.checked = false;              // unchecks user-selected checkboxes
					item.removeAttribute('checked'); // unchecks prepopulated checkboxes
				});

				// Reset current page.
				resetCurrentPage();

				// Reset query string.
				resetQueryString();

				// Clear results.
				clearResults();

				// Collapse filters and scroll to results.
				//scrollToResultsContainer();

				// Fetch posts.
				fetchPosts(getFilters(), 1);

				// Add disabled state to reset and submit buttons
				addHiddenState(resetBtn);
				addDisabledState(submitBtn);

			}

		}

	}

	/**
	 * Checks filters to determine if empty.
	 * If empty, disable reset.
	 */
	function maybeDisableReset() {

		const publicFilters = getPublicFilters();

		if (helpers.isEmpty(publicFilters)) {
			addHiddenState(resetBtn);
		} else {
			removeHiddenState(resetBtn);
		}

	}

	/**
	 * Add disabled state
	 * @param  el  {Nodelist}
	 */
	function addDisabledState(el) {
		el.classList.add(btnDisabledClass);
		el.disabled = true;
	}

	/**
	 * Remove disabled state
	 * @param  el  {Nodelist}
	 */
	function removeDisabledState(el) {
		el.classList.remove(btnDisabledClass);
		el.disabled = false;
	}

	/**
	 * Add hidden state
	 * @param  el  {Nodelist}
	 */
	function addHiddenState(el) {
		el.classList.add(btnHiddenClass);
		el.disabled = true;
	}

	/**
	 * Remove hidden state
	 * @param  el  {Nodelist}
	 */
	function removeHiddenState(el) {
		el.classList.remove(btnHiddenClass);
		el.disabled = false;
	}

	/**
	 * Submit click.
	 * If the submit button is clicked, calls handleFormSubmit.
	 * @param  {Event} e
	 */
	function handleFiltersSubmitBtn(e) {

		if (e.target === submitBtn) {
			handleFormSubmit(e);
		}

	}

	/**
	 * Scroll to top of results container
	 */
	function scrollToResultsContainer() {
		helpers.scrollToEl($(filterForm), els.header.offsetHeight);
	}

	/**
	 * Load more posts into the results container.
	 * @param {Event} e
	 */
	function handleLoadMore(e) {

		if (e.target === loadMoreBtn) {
			if (loadMoreBtn.classList.contains('btn--loading')) {
				return;
			} else {
				loadMoreBtn.disabled = true;
			}
			if (hasMorePages()) {
				incrementCurrentPage();
				fetchPosts(helpers.mergeObjects(lastSubmissionPublicFilters, getHiddenFilters()), 0);
			}
		}

	}

	/**
	 * Handle form submission
	 * @param  {Event} e
	 */
	function handleFormSubmit(e) {

		// Prevent default submission to the current page.
		e.preventDefault();

		if (submitBtn.classList.contains(btnDisabledClass)) {
			return;
		}

		resetCurrentPage();
		clearResults();

		// Collapse filters and scroll to results.
		//scrollToResultsContainer();

		// Fetch posts.
		fetchPosts(getFilters(), 1);

		// Add disabled state to submit button
		addDisabledState(submitBtn);

	}

	/**
	 * Reset the hidden input for current page.
	 */
	function resetCurrentPage() {
		inputCurrentPage.value = '';
	}

	/**
	 * Reset the hidden input for current page.
	 */
	function resetPages() {
		inputTotalPages.value = '';
		inputTotalPosts.value = '';
	}

	/**
	 * Clear the results in the posts container.
	 */
	function clearResults() {
		$(resultsContainer).children().remove();
	}

	/**
	 * Get the current filters in query string format
	 */
	function getCurrentSubmissionQueryString() {
		return convertFiltersToQueryString(getPublicFilters());
	}

	/**
	 * Hit the REST API to fetch posts
	 * @param {Object} filters  The current set of form filters (public and hidden) from getFilters().
	 * @param {Boolean} newSubmission  True if submitting the form. False if load more.
	 */
	function fetchPosts(filters, newSubmission) {
		if ($('#js-filter-results').children.length < 9) {
			let queryString = '';

			// Get all the current checkbox selections.
			if (newSubmission) {
				queryString = getCurrentSubmissionQueryString();
				// new submission, so update the lastSubmissionQueryString.
				lastSubmissionQueryString = queryString;
				lastSubmissionPublicFilters = getPublicFilters();
			} else {
				queryString = lastSubmissionQueryString;
			}

			// Prevent double submissions
			if (filterForm.classList.contains('submitting')) {
				return;
			} else {
				filterForm.classList.add('submitting');
				loadMoreBtn.classList.add('btn--loading');
				maybeDisableReset();
				lastSubmissionValues = getSelectedCheckboxes();
			}

			// Make the request and perform actions based on response.
			$.ajax({
				type: "GET",
				url: inputEndpoint.value,
				dataType: 'json',
				data: filters,
				timeout: 10000, // sets timeout to 10 seconds
				error: function (data) {
					handleError(data);
				},
				success: function (data, status, jqXHR) {
					handleSuccess(data, status, jqXHR);
					if (newSubmission) {
						doCheckboxCounters();
						updateURL(queryString);
					}
				}
			});
		} else {
			setTimeout(fetchPosts(filters, newSubmission), 300);
		}
	}

	/**
	 * Ajax error
	 */
	function handleError(data) {

		filterForm.classList.remove('submitting');
		loadMoreBtn.classList.remove('btn--loading');
		loadMoreBtn.disabled = false;
		if (resultsContainer.children.length > 0) {
			clearResults();
		}
		displayError(data);
		doCheckboxCounters();

	}

	/**
	 * Ajax success
	 */
	function handleSuccess(data, status, jqXHR) {

		filterForm.classList.remove('submitting');
		loadMoreBtn.classList.remove('btn--loading');
		loadMoreBtn.disabled = false;
		updatePagination(data, jqXHR);
		maybeShowBtnLoadMore();
		displayPosts(data);

	}

	/**
	 * Increment the current page.
	 */
	function incrementCurrentPage() {

		// store the old value. If no value, sets it to 1.
		let oldValue = parseInt(inputCurrentPage.value) || 1;
		const newValue = ++oldValue;

		inputCurrentPage.value = newValue;

	}

	/**
	 * Update the pagination filters based on data retrieved from REST API.
	 *
	 * - Default WP API endpoints include response headers in the response to indicate
	 *   pagination variables.
	 * - Resource Manager endpoint structures data differently, and includes pagination
	 *   variables inside the response.
	 *
	 * @param   object   data   Response retrieved from ajax call. Used by Resources object.
	 * @param   object   jqXHR  Headers response from ajax call. Used by News / Events object.
	 */
	function updatePagination(data, jqXHR) {

		inputTotalPages.value = jqXHR.getResponseHeader('X-WP-TotalPages');
		inputTotalPosts.value = jqXHR.getResponseHeader('X-WP-Total');

	}

	/**
	 * Maybe show the Load More button.
	 */
	function maybeShowBtnLoadMore() {

		if (hasMorePages()) {
			// Show the load more button.
			loadMoreBtn.classList.remove('btn--is-hidden');
		} else {
			// Hide the load more button.
			loadMoreBtn.classList.add('btn--is-hidden');
		}

	};

	/**
	 * Helper function for determining if there are more pages of posts.
	 * Compares current page val to total pages val.
	 * Returns true if current page val is less than total pages val.
	 */
	function hasMorePages() {

		// This might not be necessary, but convert values to integers to ensure we can do math.
		const totalPageVal = Number(inputTotalPages.value);
		let currentPageVal = Number(inputCurrentPage.value);

		if (currentPageVal === 0) {
			currentPageVal = 1;
		}

		if (currentPageVal < totalPageVal) {
			return true;
		} else {
			return false;
		}

	};

	/**
	 * General method for displaying posts retrieved from REST API.
	 * @param {Object} Data returned from REST API.
	 */
	function displayPosts(data) {

		// If we have posts at the resource manager endpoint, continue.
		if (data && !helpers.isEmpty(data)) {
			helpers.forEach(data, (i, post) => {
				let args = formatPostData(post);
				resultsContainer.appendChild(getCardHTML(args));
			});
			initLazyLoad();
			$(resultsContainer).fadeIn(300);
			console.log(resultsContainer.children);
		} else {
			displayError(data);
		}

	};

	/**
	 * Format the results from REST API for use in a card component.
	 * @param {Object} WordPress post object.
	 */
	function formatPostData(post) {

		let args = {};

		// Image
		if (post.hasOwnProperty('featured_image_HTML')) {
			args.image = post.featured_image_HTML;
		}

		// Label
		if (post.hasOwnProperty('label')) {
			args.label = post.label;
		}

		// Label Color
		if (post.hasOwnProperty('color')) {
			args.label_color = post.color;
		}

		// Label Icon
		if (post.hasOwnProperty('icon') && post.icon) {
			// Bugfix for when fontawesome returns name of class rather than icon element
			// when querying Webinars + a resource type.
			if (post.icon.lastIndexOf('fa-', 0) === 0) {
				// post.icon is just a class name. wrap with icon html.
				args.label_icon = '<i class="fa ' + post.icon + '" aria-hidden="true"></i>';
			} else {
				// post.icon is an icon element. no change necessary.
				args.label_icon = post.icon;
			}
		}

		// Title
		if (post.hasOwnProperty('title')) {
			args.title = post.title;
		}

		// Link (object)
		if (post.hasOwnProperty('link')) {
			args.link = post.link;
		}

		return args;

	}

	/**
	 * Generate the HTML for a card.
	 * @param {Object} Data intended to display components for a card.
	 */
	function getCardHTML(args) {

		let cardGridItem = document.createElement('div');
		let card = '';
		let cardLabel = '';

		// Add card classes
		cardGridItem.classList.add('card-column', 'col-sm-6', 'col-lg-4', 'fade-in');

		// Build the label
		if (args.hasOwnProperty('label')) {
			cardLabel += '' +
				'<div class="card__label text-label text-label--w-separator' + (args.hasOwnProperty('label_icon') ? ' text-label--w-icon' : '') + (args.hasOwnProperty('label_color') ? ' text-color-' + args.label_color : '') + '">' +
				(args.hasOwnProperty('label_icon') ? args.label_icon + ' ' : '') + args.label +
				'</div>' +
				'';

		}

		// Create Inner Card HTML
		card += '' +
			'<div class="card card--reveal card--blur">' +
			'<a href="' + args.link.url + '" class="card__link"' + (args.link.target ? ' target="_blank" rel="noopener noreferrer"' : '') + '>' +
			'<div class="card__inner">' +
			'<div class="card__media"><div class="media-frame media-frame--cover">' + (args.hasOwnProperty('image') ? args.image : '<img data-original="' + fallbackImage + '" width="380" height="214" alt="Fallback Evidera Image" />') + '</div></div>' +
			'<div class="card__body">' +
			cardLabel +
			'<h3 class="card__title">' + args.title + '</h3>' +
			'<div class="card__reveal-content">' +
			'<span class="link link--arrow text-color-green">' + args.link.title + '</span>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</a>' +
			'</div>' +
			'';
		cardGridItem.innerHTML = card;

		return cardGridItem;

	}

	/**
	 * Show error message.
	 * @param {Object} data.
	 */
	function displayError(data) {

		// Reset current page.
		resetCurrentPage();
		resetPages();
		resetQueryString();
		maybeShowBtnLoadMore();

		let errorText = '';

		if (data.statusText == 'timeout') {
			errorText = 'We\'re sorry, the request timed out. Try resetting or submitting the form again.';
			removeDisabledState(submitBtn);
			removeDisabledState(resetBtn);
		} else if (data.responseJSON.hasOwnProperty('message')) {
			errorText = data.responseJSON.message;
		}

		// Create a document node.
		let errorDiv = document.createElement('div');

		// Add classes.
		errorDiv.classList.add('filter-results__error', 'error', 'text-center', 'fade-in');

		// Create an error message.
		let errorMessage = '' +
			'<div class="filter-results__error text-center">' +
			'<p>' + errorText + '</p>' +
			'</div>' +
			'';

		// Add HTML to the document node.
		errorDiv.innerHTML = errorMessage;

		// Show the results container.
		$(resultsContainer).show();

		// Append the new error message to the container.
		$(resultsContainer).fadeIn(300, function () {
			resultsContainer.appendChild(errorDiv);
		});

	}


	/**
	 * Returns a query string formatted string based on the filters object passed.
	 * Expects an object of public-facing filters.
	 * Uses key/value pairs to build the querystring.
	 *
	 * @param   object  filters       Expects the filters object.
	 * @return  string  queryString   The converted filters object written as a query string.
	 */
	function convertFiltersToQueryString(filters) {

		// Return if no filters passed, or if its an empty object.
		// http://stackoverflow.com/a/32108184
		if (!filters || (Object.keys(filters).length === 0 && filters.constructor === Object)) {
			// Return empty string if filters are empty.
			return '';
		}

		// Start an empty array of extracted properties from filters object.
		let queryString = [];

		// Loop through the filter array keys to set query string parameters and values.
		for (var key in filters) {

			// skip this iteration if empty, 0, null, etc
			if (filters[key] === undefined || filters[key].length < 1) {
				continue;
			}

			// If the value for this key is an array, lets join them into one string, separted by comma;
			if (filters[key].constructor === Array) {
				queryString.push(key + '=' + filters[key].join(','));
			} else {
				queryString.push(key + '=' + filters[key]);
			}
		}

		// Join each item in the array with & to build out each filter into one string.
		queryString = queryString.join('&');

		return queryString;

	}

	/**
	 * Updates browser URL using replaceState().
	 * @param  {String} queryString  The string of query parameters. that will be used to push to the address bar.
	 */
	function updateURL(queryString) {

		if (history.pushState) {
			var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;

			// If querystring exists, and not set to empty string, add the query string to window URL.
			if (queryString && queryString !== '') {
				newurl += '?' + queryString;
			}

			window.history.replaceState({ path: newurl }, '', newurl);

		}

	}

	/**
	 * Gets checked boxes in an array.
	 */
	function getSelectedCheckboxes() {

		let choices = [];

		// Iterate through all the checkboxes for this filter group.
		helpers.forEach(checkboxes, (i, checkbox) => {
			if (checkbox.checked == true) {
				choices.push(checkbox.value);
			}
		});

		return choices;

	};

	/**
	 * Get the current filters.
	 */
	function getFilters() {

		// Get all the current checkbox selections.
		const publicFilters = getPublicFilters();
		const hiddenFilters = getHiddenFilters();

		return helpers.mergeObjects(publicFilters, hiddenFilters);

	};

	/**
	 * Handle checkbox change events
	 * @param  {Event}  e
	 */
	function handleCheckboxEvent(e) {

		// Detect if we should disable reset, or enable submit or not
		if (helpers.matchesSelector(e.target, checkboxSelector)) {

			// Store current submitted array of checkboxes
			const newSelection = getSelectedCheckboxes();

			// If the new checked values array !== the last submitted checked values array, enable submit.
			if (JSON.stringify(newSelection) == JSON.stringify(lastSubmissionValues)) {
				addDisabledState(submitBtn);
			} else {
				removeDisabledState(submitBtn);
			}

		}
	}


	/**
	 * After form submission, loops through each checkbox-select group and
	 * adds a counter for its checkboxes next to its label
	 */
	function doCheckboxCounters() {

		let fieldCounter = 0;
		let $fieldInstance = null;

		$('.checkbox-select').each(function () {
			$fieldInstance = $(this);
			fieldCounter = 0; // always reset to 0
			fieldCounter = $fieldInstance.find('[type="checkbox"]:checked').length;
			if (fieldCounter !== 0) {
				$fieldInstance.find('.checkbox-select__counter').text('(' + fieldCounter + ')');
			} else {
				$fieldInstance.find('.checkbox-select__counter').text('');
			}
		});

	}

	/**
	 * Initialize lazy loading
	 */
	function initLazyLoad() {

		const lazyload = new LazyLoad({
			show_while_loading: true,
			skip_invisible: false,
			callback_set: function (img) {
				picturefill({
					reevaluate: true,
					elements: [img]
				});
				objectFitImages(img);
			},
		});
	};


	document.addEventListener('click', handleFiltersReset);
	document.addEventListener('click', handleFiltersSubmitBtn);
	document.addEventListener('click', handleLoadMore);
	document.addEventListener('change', handleCheckboxEvent);
	filterForm.addEventListener('submit', handleFormSubmit);
	document.addEventListener('DOMContentLoaded', doCheckboxCounters);

}
