/**
 * Import dependencies.
 */
import $ from 'jquery';
import 'slick-carousel'; // hardcoded slick plugin. odd issue with gulp using node modules version.

/**
 * Slider elements
 * @type {NodeList}
 */
const impactSlider = document.querySelectorAll('.impact-statement-slider');

/**
 * Class used for each slide.
 * @type {String}
 */
const impactSlideClass = 'impact-statement-slide';

function initSlickImpactStatement() {
	
	$(impactSlider).each(function() {
		let $this = $(this);
		if ($this.find('.' + impactSlideClass).length > 1) {
			$this.slick({
				arrows: true,
				dots: false,
				nextArrow: '<button class="slider-arrow slider-arrow--next slider-arrow--lg slider-arrow--transparent-dark"></button>',
				prevArrow: '<button class="slider-arrow slider-arrow--prev slider-arrow--lg slider-arrow--transparent-dark"></button>',
				swipe: true
			});
		}
	});
};

window.addEventListener('DOMContentLoaded', initSlickImpactStatement);