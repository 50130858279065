/**
 * Import dependencies.
 */
import $ from 'jquery';
import 'slick-carousel'; // hardcoded slick plugin. odd issue with gulp using node modules version.
import * as helpers from '../global/helpers';

if ($('body').hasClass('page-template-tmpl-evidence-forum')) {

	$(document).ready(function () {

		$('.past-issues').slick({
			arrows: true,
			dots: false,
			nextArrow: '<button class="slider-arrow slider-arrow--next slider-arrow--lg"></button>',
			prevArrow: '<button class="slider-arrow slider-arrow--prev slider-arrow--lg"></button>',
			slidesToScroll: 1,
			slidesToShow: 3,
			swipe: true,
			responsive: [
				{
					breakpoint: 940,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}
			]
		});

		var currentIssue = $('.past-issues').slick('slickCurrentSlide');
		var totalIssues = $('.slick-slide:not(.slick-cloned)').length;
		// console.log('Current Issue: ' + currentIssue);

		// Hide all whitepapers except for curret slide's
		$('.whitepaper-item:not(.issue-' + currentIssue + ')').hide();

		// Set Correct Download Issue URL
		var currentIssueURL = $(".slick-current > div > .issue-item > .this-issue-carrat > #download-issue-url").attr("href");
		$("#download-issue-button").attr("href", currentIssueURL);

		// On Slider Navigation Click
		$('.slider-arrow').on('click', function() {

			// Reset current issue
			currentIssue = $('.past-issues').slick('slickCurrentSlide');
			// console.log('Current Issue: ' + currentIssue);

			// Hide all whitepapers that aren't from the current issue
			$('.whitepaper-item:not(.issue-' + currentIssue + ')').fadeOut();

			// Append next issue whitepapers to top of list and fade in
			$('.issue-' + currentIssue).prependTo('.current-issue-whitepapers');
			$('.issue-' + currentIssue ).fadeIn();

			// Reset Correct Download Issue URL
			var currentIssueURL = $(".slick-current > div > .issue-item > .this-issue-carrat > #download-issue-url").attr("href");
			$("#download-issue-button").attr("href", currentIssueURL);

		});

	});

}

(function ($) {

	// $(document).ready(function () {

	var els = document.querySelectorAll('.menu-item > a');
	console.log('els: ' + els);
	if(!els ) {
		// If is single resource page
		if (window.location.href.indexOf("resource") != -1) {

			// If this resource doesn't require registration
			if (window.location.href.indexOf("?doc=") === -1) {

				// Get direct link to resource file
				var resourceFileURL = $("#resource-link").attr("href");
				var oldURL = document.referrer;
				var evidenceForumURL = 'https://www.evidera.com/thought-leadership/our-publication-the-evidence-forum/';

				console.log('Old URL: ' + oldURL);

				if (oldURL === '') {
					// No page referrer - do nothing
				}
				if (oldURL.indexOf("our-publication-the-evidence-forum") != -1) {
					// User came from Evidence Forum - redirect straight to resource file
					window.location = resourceFileURL;
					console.log('oldURL: ' + oldURL);
					console.log('resourceFileURL: ' + resourceFileURL);
				}
				if (oldURL.indexOf("our-publication-the-evidence-forum") != -1 && window.performance && window.performance.navigation.type == window.performance.navigation.TYPE_BACK_FORWARD) {
					// User came from Evidence Forum, viewed PDF, hit back button to get here - redirect back to Evidence Forum
					window.location = evidenceForumURL;
				}

			}

		}
	}


	// });

})(jQuery);
