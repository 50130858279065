import es6promise from 'es6-promise';
es6promise.polyfill();
import FontFaceObserver from 'fontfaceobserver/fontfaceobserver.standalone';

(function(){
	// if the class is already set, we're good.
	if( document.documentElement.className.indexOf( "fonts-loaded" ) > -1 ){
		return;
	}
	var fontA = new FontFaceObserver( "Roboto", {
		weight: 300
	});
	
	var fontB = new FontFaceObserver( "Roboto", {
		weight: 400
	});
	
	var fontC = new FontFaceObserver( "Roboto", {
		weight: 500
	});
	
	var fontD = new FontFaceObserver( "Roboto", {
		weight: 700
	});
	
	var fontE = new FontFaceObserver( "Roboto", {
		weight: 900
	});
	var fontF = new FontFaceObserver( "Roboto Condensed", {
		weight: 700
	});
	Promise
		.all([fontA.load(), fontB.load(), fontC.load(), fontD.load(), fontE.load(), fontF.load()])
		.then(function(){
			document.documentElement.className += " fonts-loaded";
			document.cookie = "childThemeFontsLoaded=true;"
		});
}( this ));