/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/**
 * Layer Type: blockSpotlightSliders content with callouts
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import 'slick-carousel';
import * as helpers from '../global/helpers';

/**
 * Slider layers
 *
 * @type {NodeList}
 */
const blockPanelSliders = document.querySelectorAll('.layer--panel-slider');

function desktopPanelSliders() {
	const defaultBG = $('.panel-slides .panel-slide:first-child').data('bg');
	// console.log(defaultBG);
	$('.panel-slides').css('background-image', 'url(' + defaultBG + ')');
	$('.panel-slide').each(function () {
		const slideBG = $(this).data('bg');
		$(this).hover(
			function () {
				$('.panel-slides').css('background-image', 'url(' + slideBG + ')');
			},
			function () {
				$('.panel-slides').css('background-image', 'url(' + defaultBG + ')');
			},
		);
	});
}

/**
 * Do synchronized slick sliders.
 */
function initBlockPanelSliders() {
	const isTouchDevice = 'ontouchstart' in document.documentElement;
	$(blockPanelSliders).each(
        
		function () {
			// This layer instance.
			// const $this = $(this);

			// This layer's content slider.
            const $slider = $(this).find('.panel-slides');
            
            console.log('INSIDE');

			// Initialize slider if more than one slide.
			if ($slider.find('.panel-slide').length > 1) {
				// Content slider properties.
				const sliderProps = {
					adaptiveHeight: false,
					arrows: true,
					dots: false,
					draggable: true,
					fade: false,
					mobileFirst: true,
					nextArrow: '<button type="button" class="panel-slider__control panel-slider__control--next"><span class="screen-reader-text">Next</span><i class="fa fa-long-arrow-right"></i></button>',
					prevArrow: '<button type="button" class="panel-slider__control panel-slider__control--prev"><span class="screen-reader-text">Previous</span><i class="fa fa-long-arrow-left"></i></button>',
				};

				if (isTouchDevice && !$('body').hasClass('ie')) { // force slider on touch devices (BH 684).
					$slider.slick(sliderProps);
				} else {
					// Initialize the content slider.
					if ($(window).width() < 769) {
						$slider.slick(sliderProps);
					} else {
						desktopPanelSliders();
					}


					const handleSlickOnResize = () => {
						if ($(window).width() > 769) {
							if ($slider.hasClass('slick-initialized')) {
								$slider.slick('unslick');
								desktopPanelSliders();
							}
						} else {
							if (!$slider.hasClass('slick-initialized')) {
								$slider.slick(sliderProps);
							}
							$slider.find('.active').removeClass('active');
						}
					};

					window.addEventListener('resize', helpers.debounce(handleSlickOnResize, 100, false));
				}
			}
		},
	);
}


window.addEventListener('DOMContentLoaded', initBlockPanelSliders);