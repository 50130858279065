/**
 * Import dependencies.
 */
import $ from 'jquery';
import 'slick-carousel'; // hardcoded slick plugin. odd issue with gulp using node modules version.
import 'picturefill';
import LazyLoad from 'vanilla-lazyload';

/**
 * Slider elements
 * @type {NodeList}
 */
const contentBoxSlider = document.querySelectorAll('.content-box-slider');

/**
 * Class used for each slide.
 * @type {String}
 */
const contentBoxClass = 'content-box-lg';

function initContentBoxSlider() {
	
	$(contentBoxSlider).each(function() {
		let $this = $(this);
		if ($this.find('.' + contentBoxClass).length > 1) {
			let sliderProps = {
				arrows: true,
				dots: false,
				fade: true,
				infinite: true,
				nextArrow: '<button class="slider-arrow slider-arrow--up slider-arrow--lg slider-arrow--transparent-dark"></button>',
				prevArrow: '<button class="slider-arrow slider-arrow--down slider-arrow--lg slider-arrow--transparent-dark"></button>',
				swipe: true
			};
			// Conditionally sync with a background image slider.
			let bgImgSlider = $this.closest('.layer').find('.media-frame-slider');
			if ( bgImgSlider.find('.media-frame-slide').length > 1 ) {
				sliderProps.asNavFor = bgImgSlider;
				bgImgSlider.slick({
					arrows: false,
					asNavFor: $this,
					dots: false,
					fade: true,
					infinite: true,
					slidesToShow: 1,
/*
					vertical: true,
					verticalSwiping: true,
*/
				});
				// On before slide change
				bgImgSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
					let lazyload = new LazyLoad({
						show_while_loading: true,
						callback_set: function(img) {
							picturefill({
								reevaluate: true,
								elements: [img]
							})
						},
					});
				});

			}
			$this.slick(sliderProps);
		}
	});
};

window.addEventListener('DOMContentLoaded', initContentBoxSlider);